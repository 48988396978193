import React, { useEffect } from "react";
import Institucional from "./pages/Institucional.jsx";
import Calculadora from "./pages/Calculadora.jsx";
import { LanguageProvider } from './context/LanguageContext';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css'

function App() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])

  return (
    <BrowserRouter>
      <LanguageProvider>
        <Routes>
          <Route path="/" element={<Institucional />} />
          <Route path="/create" element={<Calculadora />} />
        </Routes>
      </LanguageProvider>
    </BrowserRouter>
  );
}
export default App;

